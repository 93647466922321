import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [2];

export const dictionary = {
		"/[lang]": [~6,[2],[3]],
		"/[lang]/blog": [~14,[2],[3]],
		"/[lang]/blog/c/[cat_name]": [~16,[2],[3]],
		"/[lang]/blog/[post_url]": [~15,[2],[3]],
		"/[lang]/brands": [~17,[2],[3]],
		"/[lang]/brands/[brand_slug]": [~18,[2],[3]],
		"/[lang]/catalogsearch/result": [~22,[2],[3]],
		"/[lang]/centro-de-ayuda": [23,[2,5],[3]],
		"/[lang]/centro-de-ayuda/otras-cuestiones": [24,[2,5],[3]],
		"/[lang]/centro-de-ayuda/postventa": [25,[2,5],[3]],
		"/[lang]/centro-de-ayuda/postventa/incidencias": [26,[2,5],[3]],
		"/[lang]/centro-de-ayuda/postventa/solicitar-factura": [27,[2,5],[3]],
		"/[lang]/centro-de-ayuda/preventa": [28,[2,5],[3]],
		"/[lang]/centro-de-ayuda/preventa/consultas-producto": [29,[2,5],[3]],
		"/[lang]/centro-de-ayuda/preventa/informacion-de-compra": [~30,[2,5],[3]],
		"/[lang]/centro-de-ayuda/preventa/informacion-generica": [~31,[2,5],[3]],
		"/[lang]/centro-de-ayuda/seguimiento": [32,[2,5],[3]],
		"/[lang]/centro-de-ayuda/seguimiento/cancelacion-pedido": [33,[2,5],[3]],
		"/[lang]/centro-de-ayuda/seguimiento/corregir-datos": [34,[2,5],[3]],
		"/[lang]/centro-de-ayuda/seguimiento/estado-pedido": [35,[2,5],[3]],
		"/[lang]/checkout/cart": [36,[2],[3]],
		"/[lang]/checkout/onepage/success": [37,[2],[3]],
		"/[lang]/contact": [~38,[2],[3]],
		"/[lang]/[[account]]/customer/account": [8,[2,4],[3]],
		"/[lang]/[[account]]/customer/address": [9,[2,4],[3]],
		"/[lang]/[[account]]/customer/settings": [10,[2,4],[3]],
		"/[lang]/c/[...cat_url]/b/all": [~21,[2],[3]],
		"/[lang]/c/[...cat_url]/b/[brand_url]": [~20,[2],[3]],
		"/[lang]/c/[...cat_url]": [~19,[2],[3]],
		"/[lang]/jobs": [~39,[2],[3]],
		"/[lang]/jobs/[job_url]": [~40,[2],[3]],
		"/[lang]/opiniones": [~41,[2],[3]],
		"/[lang]/p/[prod_url]": [~42,[2],[3]],
		"/[lang]/[[account]]/sales/order/history": [11,[2,4],[3]],
		"/[lang]/[[account]]/sales/order/view/order_id/[order_id]": [12,[2,4],[3]],
		"/[lang]/u/[...universe_url]": [~43,[2],[3]],
		"/[lang]/[static]": [~13,[2],[3]],
		"/[lang]/[...path]": [~7,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';