import * as Sentry from '@sentry/sveltekit'
import { handleErrorWithSentry } from '@sentry/sveltekit'

Sentry.init({
	dsn: 'https://99a6e17ef3df43adba0aaa61f402e8b5@o482760.ingest.sentry.io/4505595612102656',
	tracePropagationTargets: [], // disable tracing
	ignoreErrors: [
		/^ResizeObserver loop/,
		/^Cannot find module/, // This occurs during production releases
		/other side closed/, // socket error
		/Load failed/,
	],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
